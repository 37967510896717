import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AcvLocationDialogComponent } from './components/acv-location-dialog/acv-location-dialog.component';

@Component({
  selector: 'acv-pin-location',
  templateUrl: './acv-pin-location.component.html',
  styleUrls: ['./acv-pin-location.component.scss'],
})
export class AcvPinLocationComponent {
  @Input() ControlLabelText: string;
  @Input() Required: boolean = false;
  @Input() IconClass: string;
  @Input() IconPosition: string;
  @Input() pin_location: string = '';

  @Output() formSubmit = new EventEmitter<any>();

  @ViewChild('ACV_LocationDialogComponent_Ref') 
  ACV_LocationDialogComponent_Ref: AcvLocationDialogComponent;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.cdr.detectChanges();
  }

  onClick() {
    this.ACV_LocationDialogComponent_Ref.show();
  }

  handleFormSubmit(formValue: any) {
    // Handle the form value as needed
    this.pin_location = formValue.manual_location;
    this.cdr.detectChanges(); // Trigger change detection
    this.formSubmit.emit(formValue);
  }
}