import { Injectable } from '@angular/core';
import { Permissions } from './permission.interface';
import { MenuItem } from 'primeng/api';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public loginToken: string;
  public userProfileData: any;
  items: MenuItem[] = [];
  user_permissions: Permissions;

  constructor(private router:Router) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        /* Your code goes here on every router change */
        let dataObj = { item: { routerLink: [ev.url] } };
        this.menuChange(dataObj);
      }
    });
  }
  // set & get token
  public settoken(token: string) {
    localStorage.setItem('access_token', token);
    this.loginToken = token;
  }

  public gettoken() {
    return this.loginToken;
  }

  // set & get user profile data
  public setuserProfileData(data: any) {
    this.userProfileData = data;

    //localStorage.setItem('userProfileData', JSON.stringify(data));
  }
  
  public getuserProfileData() {
    return this.userProfileData;
    //return JSON.parse(localStorage.getItem('userProfileData') || '{}');
  }

  getUserName() {
    return this.userProfileData.username;
  }

  setPermissions() {
    if (
      localStorage.getItem('user_permissions') != 'undefined' &&
      localStorage.getItem('user_permissions') != 'null'
    ) {
      this.user_permissions = JSON.parse(
        localStorage.getItem('user_permissions') || '{}'
      );
    }
  }

  setMenus(){
    this.setPermissions();
    this.items = [
      {
        label: 'Manage Trips',
        icon: 'pi pi-fw ic-import',
        expanded: this.checkActiveState('/manage-trips'),
        styleClass: 'has-childs',
        routerLink: ['/manage-trips/calendar/list'],
        command: (e:any) => this.menuChange(e),
        routerLinkActiveOptions: { exact: false },
        items: [
          { label: 'Calendar', icon: 'ic-calednar', routerLink: ['/manage-trips/calendar/list'], routerLinkActiveOptions: { exact: true }, command: (e:any) => this.menuChange(e), },
          { label: 'Trip Requests', icon: 'ic-head-phones', routerLink: ['/manage-trips/trip-requests/list'], routerLinkActiveOptions: { exact: true }, command: (e:any) => this.menuChange(e), },
          { label: 'Trip Rejects', icon: 'ic-cancel-circle', routerLink: ['/manage-trips/trip-rejects/list'], routerLinkActiveOptions: { exact: true }, command: (e:any) => this.menuChange(e), },
        ]
      },
      {
        label: 'Settings',
        icon: 'pi pi-fw ic-setting',
        expanded: this.checkActiveState('/setting'),
        styleClass: 'has-childs',
        routerLink: ['/settings/users'],
        routerLinkActiveOptions: { exact: false },
        items: this.filterItems([
          { label: 'Users', icon: 'ic-person', routerLink: ['/settings/users/list'], routerLinkActiveOptions: { exact: true }, command: (e:any)  => this.menuChange(e),  },
          { label: 'Roles', icon: 'ic-role', routerLink: ['/settings/roles/list'], routerLinkActiveOptions: { exact: false }, command: (e:any)  => this.menuChange(e), },
          { label: 'Customers', icon: 'ic-customer', routerLink: ['/settings/customers/list'], routerLinkActiveOptions: { exact: false }, command: (e:any)  => this.menuChange(e), },
          { label: 'Trucks', icon: 'ic-truck', routerLink: ['/settings/trucks/list'], routerLinkActiveOptions: { exact: true }, command: (e:any) => this.menuChange(e), },
          { label: 'Trailers', icon: 'ic-trailers', routerLink: ['/settings/trailers/list'], routerLinkActiveOptions: { exact: true }, command: (e:any) => this.menuChange(e), },
          { label: 'Drivers', icon: 'ic-drivers', routerLink: ['/settings/drivers/list'], routerLinkActiveOptions: { exact: true }, command: (e:any) => this.menuChange(e), },
          { label: 'Locations', icon: 'ic-location', routerLink: ['/settings/locations/list'], routerLinkActiveOptions: { exact: true }, command: (e:any)  => this.menuChange(e), },
        ], this.user_permissions.Settings)
        
      },
      {
        label: 'Setup',
        icon: 'pi pi-fw ic-setting-gear',
        expanded: this.checkActiveState('/setup'),
        styleClass: 'has-childs',
        routerLink: ['/setup/services'],
        routerLinkActiveOptions: { exact: false },
        items: this.filterItems([
          { label: 'Services', icon: 'ic-head-phones', styleClass: this.isLinkActive('/setup/services/') ? 'p-menuitem-link-active' : '', routerLink: ['/setup/services/list'], routerLinkActiveOptions: { exact: true }, command: (e:any) => this.menuChange(e) },
          { label: 'Legs', icon: 'ic-pin-location', routerLink: ['/setup/legs/list'], routerLinkActiveOptions: { exact: true }, command: (e:any) => this.menuChange(e) },
          { label: 'Milestones', icon: 'ic-milestone', routerLink: ['/setup/milestones/list'], routerLinkActiveOptions: { exact: true }, command: (e:any) => this.menuChange(e) },
          { label: 'Actions', icon: 'ic-aim', routerLink: ['/setup/actions/list'], routerLinkActiveOptions: { exact: true }, command: (e:any) => this.menuChange(e) },
        ], this.user_permissions.Setup)
      },
      {
        label: 'Report',
        icon: 'pi pi-fw ic-report',
        expanded: this.checkActiveState('/reports'),
        styleClass: 'single',
        routerLink: ['/reports/detail'],
        routerLinkActiveOptions: { exact: false },
      },
    ];
    this.filterMainMenus();
  }

  filterItems(items: any[], permissions: any): any[] {
    return items.filter((item) => {
      const hasPermission = permissions[item.label]['view'];
      return hasPermission;
    });
  }

  filterMainMenus(){
    let permissions:any = this.user_permissions;
    let allowed_menus:any[] = [permissions?.Reports?.Report.view === true ? "Report" : ""];
    Object.keys(permissions).forEach((key: string) => {
      let found_active_submenu = false;
      Object.keys(permissions[key]).forEach((subkey:any) => {
        if(permissions[key][subkey]["view"]){
          found_active_submenu = true;
          return;
        }
      });
      if(found_active_submenu){
        allowed_menus.push(key);
      }
    });
    
    this.items = this.items.filter((item:any) => {
      const hasPermission = allowed_menus.includes(item.label.replaceAll(' ', ''));
      return hasPermission;
    });
  }

  checkActiveState(givenLink: any) {
    //console.log(givenLink)
    if (this.router.url.indexOf(givenLink) === -1) {
      return false;
    } else {
      return true;
    }
  }

  isLinkActive(link: string) {
    const url = this.router.url;
    //console.log(url);
    
    return link === url.substring(1, url.indexOf('?'));
  }

  menuChange(evnt: any) {
    if (evnt.item.routerLink) {
      //console.log(evnt.item.routerLink[0].split('/'));

      let route_arr = evnt.item.routerLink[0].split('/');
      let route_url = `/${route_arr[1]}/${route_arr[2]}`;

      this.items.forEach((menuItem) => {
        if (menuItem.items && menuItem.items?.length > 0) {
          //Checking if any match route exist in sub-menus
          let findMatchingRoute = menuItem.items.find((x) =>
            x.routerLink[0].includes(route_url)
          );

          if (findMatchingRoute) {
            menuItem.expanded = true;
          } else {
            menuItem.expanded = false;
          }
        }
      });

      this.checkActiveSubMenu(evnt.item.routerLink[0].split('/').splice(0,3).join("/"))
    }
  }

  checkActiveSubMenu(route:any = null){
    let menus = document.querySelectorAll(".p-panelmenu-root-submenu li a");
    
    menus.forEach((item:any) => {
      let match_path = route ? route : this.router.url.split('/').splice(0,3).join("/");
      
      if(item?.href.includes(match_path)){
        item.classList.add("p-menuitem-active");
      }
      else{
        item.classList.remove("p-menuitem-active");
      }
    });
  }
}
