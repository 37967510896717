import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { retry, retryWhen, timer } from 'rxjs';
import { ApienvService } from 'src/app/core/services/apienv.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpService {
  constructor(
    private http: HttpClient,
    private ApienvService: ApienvService,
    private _router: Router
  ) {}

  async get(url: string, options?: any) {
    let result: any;
    const request_options = this.headerSetup('application/json');

    await this.http
      .get(this.ApienvService.API_Config.apiUrl + url, request_options)
      .pipe(retry({ count: 2, delay: this.shouldRetry }))
      .toPromise()
      .then(async (resp) => {
        result = await resp;
      })
      .catch((error) => {
        if (error?.status == 401) {
          localStorage.removeItem('access_token');
          this._router.navigate(['/login']);
        }
        result = error;
      });

    return result;
  }

  async post(url: string, data: any, options?: any) {
    let result: any;
    let request_options = this.headerSetup('application/json');
    await this.http
      .post(this.ApienvService.API_Config.apiUrl + url, data, request_options)
      .pipe(retry({ count: 2, delay: this.shouldRetry }))
      .toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        if (error?.status == 401) {
          localStorage.removeItem('access_token');
          // this._router.navigate(['/login']);
        }
        //throw error;
        result = error;
      });

    return result;
  }

  async download(url: string, data: any, options?: any) {
    let result: any;
    let request_options = this.headerSetup('application/json');
    await this.http
      .post(this.ApienvService.API_Config.apiUrl + url, data, {
        responseType: 'blob',
        headers: new HttpHeaders().append('Content-Type', 'application/json'),
      })
      .pipe(retry({ count: 2, delay: this.shouldRetry }))
      .toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        if (error?.status == 401) {
          localStorage.removeItem('access_token');
          // this._router.navigate(['/login']);
        }
        result = error;
      });

    return result;
  }

  async put(url: string, data: any, options?: any) {
    let result: any;
    //const request_options = this.headerSetup('application/json');
    await this.http
      .put(this.ApienvService.API_Config.apiUrl + url, data, options)
      .pipe(retry({ count: 2, delay: this.shouldRetry }))
      .toPromise()
      .then(async (resp) => {
        result = await resp;
      })
      .catch((error) => {
        if (error?.status == 401) {
          localStorage.removeItem('access_token');
          this._router.navigate(['/login']);
        }
        result = error;
      });

    return result;
  }

  async delete(url: string, options?: any) {
    let result: any;

    await this.http
      .delete(this.ApienvService.API_Config.apiUrl + url, options)
      .pipe(retry({ count: 2, delay: this.shouldRetry }))
      .toPromise()
      .then(async (resp) => {
        result = await resp;
      })
      .catch((error) => {
        if (error?.status == 401) {
          localStorage.removeItem('access_token');
          this._router.navigate(['/login']);
        }
        result = error;
      });

    return result;
  }

  async postAttachmentApi(url: any, key: any, value?: any, headerson?: any) {
    let result: any;
    const request_options = this.headerSetup('multipart/form-data;');
    const uploadData: FormData = new FormData();

    if (value) {
      uploadData.append(key, value);
    }

    await this.http
      .post(
        this.ApienvService.API_Config.apiUrl + url,
        uploadData,
        request_options
      )
      .pipe(retry({ count: 2, delay: this.shouldRetry }))
      .toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        if (error?.status == 401) {
          localStorage.removeItem('access_token');
          this._router.navigate(['/login']);
        }
        result = error;
      });

    return result;
  }

  shouldRetry(error: HttpErrorResponse) {
    // Example for catching specific error code as well
    if (error.status === 503 || error.status === 0) {
      return timer(1000); // Adding a timer from RxJS to return observable to delay param.
    }
    throw error;
  }

  headerSetup(contentType: any) {
    const token = localStorage.getItem('access_token')
      ? localStorage.getItem('access_token')
      : '';
    const headerObj = {
      //'Content-Type': contentType,
      enctype: contentType,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept',
      Authorization: 'Bearer ' + token,
    };

    const obj = Object.assign({}, headerObj);
    const httpHeaders = new HttpHeaders(obj);
    return { headers: httpHeaders };
  }
}
