import { Component, EventEmitter, Input, Output, AfterViewInit, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-acv-location-dialog',
  templateUrl: './acv-location-dialog.component.html',
  styleUrls: ['./acv-location-dialog.component.scss'],
})
export class AcvLocationDialogComponent implements AfterViewInit, OnChanges {
  show_Dialog: boolean = false;
  data_type: string = 'list';
  view_mode: string = 'grid';
  title: string = 'Location';
  center: google.maps.LatLngLiteral = { lat: 37.7749, lng: -122.4194 }; // Default center
  zoom = 10; // Default zoom level
  markerPosition: google.maps.LatLngLiteral = { lat: 37.7749, lng: -122.4194 }; // Default marker position
  markerOptions: google.maps.MarkerOptions = { draggable: true }; // Marker options
  complete_address: string = '';
  locationForm: FormGroup;
  action_data: any;

  @ViewChild('autocompleteInput') autocompleteInput: ElementRef;

  constructor(public dialogService: DialogService, private fb: FormBuilder) {}

  @Output() formSubmit = new EventEmitter<any>();
  @Input() pin_location: string = '';

  show() {
    this.show_Dialog = true;
  }

  hide() {
    this.show_Dialog = false;
  }

  ngOnInit() {
    this.initializeForm();
    this.view_mode = 'map';
  }

  ngAfterViewInit() {
    this.LoadMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pin_location'] && changes['pin_location'].currentValue) {
      console.log('Pin Location:', this.pin_location);
      this.updateMapLocation(this.pin_location);
    }
  }

  initializeForm() {
    this.locationForm = this.fb.group({
      manual_location: [
        this.action_data?.manual_location
          ? this.action_data?.manual_location
          : this.pin_location !== ''
          ? this.pin_location
          : `${this.center.lat}, ${this.center.lng}`,
      ],
    });
  }

  LoadMap() {
    const input = this.autocompleteInput.nativeElement as HTMLInputElement;

    if (!input) {
      console.error('Autocomplete input element not found!');
      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(input, {
      types: [], // Allow all types of places
      componentRestrictions: { country: ['us', 'mx'] }, // Restrict to US and Mexico
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const location = place.geometry.location;
        this.center = { lat: location.lat(), lng: location.lng() };
        this.markerPosition = { lat: location.lat(), lng: location.lng() };
        this.locationForm.controls['manual_location'].setValue(`${location.lat()}, ${location.lng()}`);
        this.locationForm.controls['manual_address'].setValue(place.formatted_address);
      }
    });

    // Add listener for manual input to handle coordinates
    input.addEventListener('change', () => {
      const inputValue = input.value.trim();
      const coordinatePattern = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/;
      if (coordinatePattern.test(inputValue)) {
        const [lat, lng] = inputValue.split(',').map(Number);
        const location = new google.maps.LatLng(lat, lng);
        this.center = { lat, lng };
        this.markerPosition = { lat, lng };
        this.locationForm.controls['manual_location'].setValue(`${lat}, ${lng}`);
        this.getAddress(lat, lng);
      }
    });
  }

  updateMapLocation(pinLocation: string) {
    const [lat, lng] = pinLocation.split(',').map(Number);
    this.center = { lat, lng };
    this.markerPosition = { lat, lng };
    this.locationForm.controls['manual_location'].setValue(`${lat}, ${lng}`);
  }

  // Handle marker drag end
  onMarkerDragEnd(event: any) {
    if (event.latLng) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.getAddress(event.latLng.lat(), event.latLng.lng());
      this.locationForm.controls['manual_location'].setValue(
        `${event.latLng.lat()}, ${event.latLng.lng()}`
      );
      console.log(event);
    }
  }

  getAddress(lat: number, lng: number): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        this.complete_address = results[0].formatted_address;
        this.locationForm.controls['manual_address'].setValue(
          results[0].formatted_address
        );
      } else {
        console.error('Geocoding error:', status);
      }
    });
  }

  async submit() {
    this.formSubmit.emit(this.locationForm.value);
    this.hide();
  }
}