<p-dialog 
[modal]="true"
[breakpoints]="{'960px': '75vw', '640px': '100vw'}" 
[visible]="saveFilterDialog_visibility" 
[draggable]="false" 
[resizable]="false"
[closable]="false"
[classList]="'saveFilter_dialog'">
    <ng-template pTemplate="header">
        <div class="dialogHeader flex align-items-center justify-content-between wd-100">
            <p>Save Filter</p>
        </div>
    </ng-template>
    
    
    <p class="field_label mb-2">Filter Name<sup>*</sup></p>
    <input type="text" pInputText [style]="{'width': '100%'}" [(ngModel)]="filter_name"
                            [placeholder]="'Enter a name for this Filter'" />
    

    <ng-template pTemplate="footer">
        <div class="filter_dialogFooter flex justify-content-end wd-100">
            <p-button type="button" label="Submit" class="p-button-text elevated-btn primary" [disabled]="filter_name == undefined || filter_name == ''" (onClick)="saveFilter()"></p-button>
            <p-button type="button" label="Cancel" class="p-button-text elevated-btn secondary" (onClick)="filter_name = '' ; saveFilterDialog_visibility = false"></p-button>
        </div>
    </ng-template>

</p-dialog>


<p-dialog [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [visible]="visibility" [draggable]="false" [classList]="'filter_dialog'" [position]="'right'"
    [resizable]="false" [closable]="false" [dismissableMask]="true" (onMaskClick)="hide()">

    <ng-template pTemplate="header">
        <div class="dialogHeader flex align-items-center justify-content-between wd-100 pl-2">
            <p>Filter By</p>
            <p-button icon="pi pi-times" (onClick)="hide()" styleClass="p-button-text p-button-rounded"></p-button>
        </div>
    </ng-template>
    <div (click)="$event.stopPropagation()">

    <p-scrollPanel [className]="'filter_scrollPanel'">
        <div class="filter_content">
            <div class="grid">
                <div class="col-12 md:col-12 lg:col-12 xl:col-12 pl-3" *ngFor="let field of filter_controls">

                    <ng-container *ngIf="field.field_type == 'select'">
                        <p class="filter_label mb-2">{{field.field_label}}</p>
                        <p-dropdown [options]="field.field_data"   selectId="'35'"
                        [placeholder]="field.placeholder" [filter]="true"
                        [emptyFilterMessage]="'No record found'" [appendTo]="'body'"
                        [(ngModel)]="field.field_value" optionLabel="name" optionValue="id" [className]="'filter_DD'" >
                            <!-- custom content for dropdown -->
                            <ng-template let-fields pTemplate="item">
                                <div class="filter_customDD flex align-items-center justify-content-between">
                                    <div>{{fields.name}}</div>
                                    <i  class="pi pi-times" *ngIf="field?.allow_delete" (click)="delete_saveFilterConfirm($event, field.field_label, fields.name)"></i>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </ng-container>
                    
                    <ng-container *ngIf="field.field_type == 'multiple'">
                        <!-- <p class="filter_label mb-2">{{field.field_label}}</p> -->
                        <ng-container *ngIf="field.field_type == 'multiple'">
                            <p class="filter_label mb-2">{{ field.field_label }}</p>
                            <p-multiSelect
                                [options]="field.field_data"
                                [placeholder]="field.placeholder"
                                [filter]="true"
                                [emptyFilterMessage]="'No record found'"
                                [appendTo]="'body'"
                                [overlayOptions]="{ styleClass: 'filterMultiselect-overlayPanelClass' }"
                                [(ngModel)]="field.field_value"
                                [optionLabel]="field.key === 'origin_id' || field.key === 'destination_id' ? 'location_name' : 'name'"
                                optionValue="id"
                                [style]="{ width: '100%' }"
                                [className]="'filter_DD'"
                            >
                                <!-- custom content for dropdown -->
                                <ng-template let-fields pTemplate="item">
                                    <div class="filter_customDD flex align-items-center justify-content-between">
                                        <div [pTooltip]="field.key === 'origin_id' || field.key === 'destination_id' ? fields.location_name : fields.name" tooltipPosition="top">
                                            {{ field.key === 'origin_id' || field.key === 'destination_id' ? fields.location_name : fields.name }}
                                        </div>
                                        <i
                                            class="pi pi-times"
                                            *ngIf="field?.allow_delete"
                                            (click)="delete_saveFilterConfirm($event, field.field_label, fields.location_name || fields.name)"
                                        ></i>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </ng-container>
                        
                    </ng-container>
                    
                    <ng-container *ngIf="field.field_type == 'text'">
                        <p class="filter_label mb-2">{{field.field_label}}</p>
                        <input type="text" pInputText [(ngModel)]="field.field_value" [style]="{'width': '100%'}"
                            [placeholder]="field.placeholder" />
                    </ng-container>

                    <ng-container *ngIf="field.field_type == 'date'">
                        <p class="filter_label mb-2">{{field.field_label}}</p>
                        <p-calendar [(ngModel)]="field.field_value"
                                     [placeholder]="field.placeholder" [showIcon]="false"
                                    inputId="icon">
                                </p-calendar>
                    </ng-container>
                    
                    <ng-container *ngIf="field.field_type == 'date-range'">
                        <p class="filter_label mb-2">{{field.field_label}}</p>
                        <p-calendar
                            [(ngModel)]="field.field_value" [placeholder]="field.placeholder" [showIcon]="false" inputId="icon"
                            [selectionMode]="'range'" [ngClass]="{'filter': error}" [appendTo]="'body'"
                        />
                        <p
                            class="fs-10 fw-400 font-poppin text-color-red pt-2"
                            *ngIf="error"
                        >
                            <span>Please select To Date</span>
                        </p>  
                    </ng-container>

                    <ng-container *ngIf="field.field_type == 'multiselect'">
                        <p class="filter_label mb-2">{{field.field_label}}</p>
                        
                        <p-multiSelect [options]="field.field_data" [className]="'multi-selectDD'" [filter]="false" [showHeader]="false" [showToggleAll]="false" [(ngModel)]="field.field_value" [defaultLabel]="field.placeholder"
                            optionLabel="text" optionValue="value" display="chip" (onChange)="onStatusSelect($event)">

                            <ng-template let-value pTemplate="selectedItems">
                                
                                <ng-container *ngIf="field.field_value && field.field_value.length > 0">
                                    <div class="p-multiselect-label" *ngFor="let option of field.field_value">
                                        <div class="p-multiselect-token" [attr.status]="option">
                                            <span class="p-multiselect-token-label">
                                                {{option}}
                                            </span>
                                            <!-- <button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-outlined"></button> -->
                                            <span class="p-multiselect-token-icon pi pi-times" (click)="multiSelect_removeItem(option, field.field_label)"></span>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!field.field_value || field.field_value.length == 0">
                                    <div>{{field.placeholder}}</div>
                                </ng-container>
                                
                            </ng-template>
                            
                        </p-multiSelect>
                        
                    </ng-container>
                </div>
            </div>
        </div>
    </p-scrollPanel>
</div>

    <ng-template pTemplate="footer">
        <div class="filter_dialogFooter flex align-items-center justify-content-between wd-100">
            <p-button type="button" label="Clear Filter" class="p-button-text elevated-btn secondary" (onClick)="resetFilter()"></p-button>
            <p-button type="button" label="Apply" class="p-button-text elevated-btn primary" (onClick)="applyFilter()"></p-button>
        </div>
    </ng-template>

</p-dialog>