<div class="flex" style="height: 100vh; flex-direction: column;">
    <div style="padding-top: 15px; padding-left: 20px; padding-right: 20px; padding-bottom: 15px; border-bottom: 1px solid #eef1f0 ;">
        <app-header></app-header>
    </div>
    
    <div class="align-content-center align-self-center flex-1 justify-content-center notfound" style="padding-left: 20px; padding-right: 20px;">
        <div class="notfound-404">
            <h1>404</h1>
        </div>

        <h2>Oops! This Page Could Not Be Found</h2>
        <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>

        <a [routerLink]="['/login']">Back To Login</a>
    </div>
</div>
