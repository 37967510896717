<p-dialog [styleClass]="'popup_export'" [draggable]="false" [resizable]="false" [closable]="false" [style]="{'width': '40%'}" [modal]="true"
    [visible]="show_ExportDialog">

    <ng-template pTemplate="header">
        <div class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted">
            <span class="text-xl font-bold">{{dialog_header}}</span>
            <p-button icon="pi pi-times" (onClick)="hide()" 
            styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"></p-button>
        </div>
    </ng-template>

    <div class="export_content_wrapper flex flex-column">
    <div class="content_title"><p>All 1240 Filtered Records</p></div>
    <div class="content_detail">
        <label>Date Range</label>
        <div class="flex align-items-center">
        <p-calendar appendTo="body" [(ngModel)]="fmdate" placeholder="From" [showIcon]="false" [readonlyInput]="true"></p-calendar>
        
        <p-calendar appendTo="body" [(ngModel)]="todate" placeholder="To" [showIcon]="false" [readonlyInput]="true"></p-calendar>
        </div>
    </div>

    </div>

    <div class="dialog_footer mt-4">
        <div class="flex align-items-center flex justify-content-end">

            <div class="n">
                <p-button type="button" label="Export file" class="p-button-text elevated-btn primary mr-2"
                    (click)="submit_file()"></p-button>
                <p-button type="button" label="Cancel" class="p-button-text elevated-btn secondary"
                    [style]="{'color':'black'}" (click)="hide()"></p-button>
            </div>
        </div>
    </div>
</p-dialog>
