<div class="grid">
    <div class="hidden p-0 col-12 md:col-12 lg:col-12 lg:flex md:flex align-items-center justify-content-between">

        <!-- <header-notification></header-notification> -->
        <div class="logo left">
            <a [routerLink]="['/login']">
            <img src="/assets/images/logo.svg" alt="ACV Logistics"></a>
        </div>
        <div class="right flex align-items-center justify-content-end gap-1">
            <div class="custom-avatar">
                <label>{{initials_char}}</label>
            </div>
            
            <div style="cursor: pointer;" class="flex flex-column" (click)="sp.toggle($event)">
                <p class="header_user_type">{{userInfo?.role}}</p>
                <p class="header_user_name">{{userInfo?.username}}</p>
            </div>
            <div (click)="sp.toggle($event)">
                <i class="pi pi-chevron-down profile_chev"></i>
                <!-- <p-splitButton (onClick)="op.toggle($event)" label="" styleClass="p-button-text user-actionbtn"></p-splitButton> -->
            </div>
    
            <p-overlayPanel #sp [autoZIndex]="true" [styleClass]="'header_profile_overlay'" [style]="{width: '8.5rem'}">
                <!-- <div class="overlay_items"(click)="sp.toggle($event)" [routerLink]="['/profile']"><i class="pi i-user"></i> Profile</div> -->
                <div style="margin-top: 10px;" class="overlay_items" (click)="sp.toggle($event); profile()"> Profile</div>
                <div style="margin-top: 10px;" class="overlay_items" (click)="sp.toggle($event); this.logout();"><i class="pi i-sign-out"></i> Logout</div>
            </p-overlayPanel>
        </div>
        
    </div>
</div>