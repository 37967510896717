import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { HistoryComponent } from '../history/history.component';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'show',
        style({
          width: '150px',
        })
      ),
      state(
        'hide',
        style({
          opacity: '0',
          width: '0px',
        })
      ),
      transition('show => hide', animate('400ms ease-in-out')),
      transition('hide => show', animate('400ms ease-in-out')),
    ]),
  ],
})
export class PageHeaderComponent implements OnInit {
  headerActions: any;
  only_LongSearch: Boolean = false;
  LongSearchValue: any;
  historydetails: any;
  show_backButton: boolean = true;
  pageActions: any[] = [];

  @Input('title') _title: any;
  @Input('detailText') _detailText: any;
  @Input('breadCrumbs') _breadCrumbs: any;
  @Input('show_create') show_create: any = true;
  @Input('show_history') show_history: any = false;
  @Input('StatusBadgeValue') StatusBadgeValue: string = "";

  @ViewChild('historySideBar_Ref') historySideBar_Ref: HistoryComponent;
  id: any;
  moduleName: string;
  @Input('onlyLongSearch') set _onlyLongSearch(value: any) {
    this.only_LongSearch = value ? value : false;
  }

  @Input('headerActions') set _headerActions(value: any) {
    this.headerActions = value;
  }

  @Input('AllowGoBack') set _AllowGoBack(value: any) {
    this.show_backButton = value;
  }

  @Output() onActionSelect = new EventEmitter<any>();
  @Output('longSearchChange') _longSearchChange = new EventEmitter<any>();

  toggle_SearchBar: string = 'hide';

  constructor(
    private _location: Location,
    private httpService: ApiHttpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {

    this.pageActions = [
      {
        label: '',
        type: 'history-button',
        command: '',
        cssClass: '',
        value: false,
        isVisible: true,
        isDisabled: false,
      },
    ];
    if(this.showHistory){
      this.headerActions = this.pageActions
    }
  }

  ngOnInit() {
    this.id = this._activatedRoute.snapshot.params['id'];
    const url = this._router.url
    const segments = url.split('/')
    this.moduleName = segments[2]
    
    if (this._title.includes('Create')) {
      if (this.headerActions?.length) {
        this.headerActions.forEach((action: any) => {
          action.value = true;
        });
      }
    }
    if (this._title.includes('Details')) {
      this.getHistoryLog();
    }
    this._activatedRoute.queryParams.subscribe((params) => {
      
      if (params['search']) {
        this.LongSearchValue = params['search'];
        this.toggle_SearchBar = this.LongSearchValue  ? 'show' : 'hide';
      }
  
    });
  }
  async getHistoryLog() {    
    let url = `/activitylogs?module=${this.moduleName}&moduleId=${this.id}`;
    const resp = await this.httpService.get(url);
    if (resp.status == 200) {
      this.historydetails = resp.data;
    }
  }

  ActionSelection(action: any) {
    this.onActionSelect.emit(action);
  }

  onItemClick(event: any){
    console.log(event?.item?.label)
    if(event?.item?.label === "Trip Request"){
      this.goBack();
    } else if(event?.item?.label === "Manage Trip"){
      this.goBackMultipleSteps(2);
    }
  }

  showHistory() {
    this.historySideBar_Ref.show();
  }
  onDDChange(e:any, item:any){
    item.value = e.value;
    this.onActionSelect.emit(item);
  }

  toggleSearchBar() {
    //alert("yes");
    // If the search bar is empty then hide it
    if (!this.LongSearchValue) {
      this.toggle_SearchBar = this.toggle_SearchBar == 'hide' ? 'show' : 'hide';
    }
    this.toggle_SearchBar = 'show';
    // Commented the below code as the search bar should be show if the search bar has some value
    // this.toggle_SearchBar = this.toggle_SearchBar == 'hide' ? 'show' : 'hide';
  }

  goBack() {
    this._location.back();
  }

  goBackMultipleSteps(steps: number){
    for (let i = 0; i < steps; i++) {
      this._location.back();
    }
  }

  onlongSearchChange(val: any) {
    if (val.length >= 2 || val.length == 0) {
      this._longSearchChange.emit(val);
    }
  }

  clearSearch() {
    this.LongSearchValue = ''; 
    this.onlongSearchChange(this.LongSearchValue); 
  }
}
