<div class="input-elem">
    <p class="text-control-label mb-2">
        {{ ControlLabelText }} <sup *ngIf="Required">*</sup>
    </p>
    <span class="flex flex-column" [ngClass]="
      IconClass && IconPosition == 'left'
        ? 'p-input-icon-left'
        : IconClass && IconPosition == 'right'
        ? 'p-input-icon-right'
        : ''
    ">
        <i class="pi {{ IconClass }}" *ngIf="IconClass"></i>
        <input [value]="pin_location" placeholder="Enter your Location" (click)="onClick()" pInputText readonly />    
    </span>
</div>
<app-acv-location-dialog #ACV_LocationDialogComponent_Ref [pin_location]="pin_location" (formSubmit)="handleFormSubmit($event)"/>