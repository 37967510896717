import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'side-filters',
  templateUrl: './side-filters.component.html',
  styleUrls: ['./side-filters.component.scss']
})
export class SideFiltersComponent {

  visibility: boolean = false;

  saveFilterDialog_visibility: boolean = false;

  filter_name: any = undefined;
  filter_controls:any[];
  error: boolean = false;

  @Input('FilterControls') set _FilterControls(value: any) {
    this.filter_controls = (value) ? value : [];
  }
  @Output() OnApplyFilter = new EventEmitter();

  constructor(private confirmationService: ConfirmationService) { }

  // FilterControls: any[] = [
  //   {
  //     field_label: 'Saved Filters',
  //     field_type: 'select',
  //     placeholder: 'Select Filter',
  //     field_value: undefined,
  //     allow_delete: true,
  //     field_data: [
  //       { text: 'Test1', value: 'Test1' },
  //       { text: 'Test2', value: 'Test2' },
  //       { text: 'Test3', value: 'Test3' },
  //       { text: 'Test4', value: 'Test4' }
  //     ], sub_fields: []
  //   },
  //   {
  //     field_label: 'Order Number',
  //     field_type: 'text',
  //     placeholder: 'Enter Order Number',
  //     field_value: undefined
  //   },
  //   {
  //     field_label: 'Ship by Date Range',
  //     field_type: 'date-range',
  //     field_value: undefined,
  //     sub_fields: [
  //       { field_label: undefined, placeholder: 'From', field_value: undefined },
  //       { field_label: undefined, placeholder: 'To', field_value: undefined }
  //     ]
  //   },
  //   {
  //     field_label: 'Order Date Range', 
  //     field_type: 'date-range', 
  //     field_value: undefined, 
  //     sub_fields: [
  //       { field_label: undefined, placeholder: 'From', field_value: undefined },
  //       { field_label: undefined, placeholder: 'To', field_value: undefined }
  //     ]
  //   },
  //   { 
  //     field_label: 'PO Number', 
  //     field_type: 'text', 
  //     placeholder: 'Enter PO Number', 
  //     field_value: undefined 
  //   },
  //   { 
  //     field_label: 'Ship to Code', 
  //     field_type: 'text', 
  //     placeholder: 'Enter Ship to Code', 
  //     field_value: undefined 
  //   },
  //   { 
  //     field_label: 'Ship to City', 
  //     field_type: 'select', 
  //     placeholder: 'Select Ship to City', 
  //     field_value: undefined,
  //     field_data: [
  //       { text: 'New York', value: 'New York' },
  //       { text: 'Los Angeles', value: 'Los Angeles' },
  //       { text: 'Houston', value: 'Houston' },
  //     ]
  //   },
  //   {
  //     field_label: 'Status', 
  //     field_type: 'multiselect', 
  //     placeholder: 'Select Status', 
  //     field_value: undefined, 
  //     field_data: [
  //       { text: 'New', value: 'New' },
  //       { text: 'Open', value: 'Open' },
  //       { text: 'On Hold', value: 'On Hold' },
  //       { text: 'Void', value: 'Void' },
  //       { text: 'Shipped', value: 'Shipped' },
  //     ], sub_fields: []
  //   },
  //   {field_label: 'Customer Number', field_type: 'text', placeholder: 'Enter Customer Number', field_value: undefined},
  //   {field_label: 'Sage Order Number', field_type: 'text', placeholder: 'Enter Sage Order Number', field_value: undefined, field_data: []},
  //   {field_label: 'Payment Check', field_type: 'select', placeholder: 'Select Payment Check', field_value: undefined, field_data: [
  //     { text: 'Yes', value: 'Yes' },
  //     { text: 'No', value: 'No' },
  //   ]},
  //   {field_label: 'Order Check', field_type: 'select', placeholder: 'Select Order Check', field_value: undefined, field_data: [{ text: 'Yes', value: 'Yes' },
  //   { text: 'No', value: 'No' }]
  // }
  // ];

  show() {
    this.visibility = true;

    setTimeout(() => {
      const maskElement = document.querySelector('.p-dialog-mask');
      
      if (maskElement) {
        maskElement.addEventListener('click', () => {
          this.hide();
        });
      } else {
        //console.error('p-dialog-mask element not found');
      }
    }, 100)
  }

  hide() {
    this.visibility = false;
  }

  delete_saveFilterConfirm(evnt: any, field:string, item:String) {
    evnt.stopPropagation();

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this filter?',
      icon: 'i-warn',
      accept: () => {
        let Ind = this.filter_controls.findIndex(x => x.field_label == field);
        if(Ind !== -1){
          this.filter_controls[Ind].field_data = this.filter_controls[Ind].field_data.filter((x:any) => x.text !== item);
        }
        //Actual logic to perform a confirmation
      }
    });
  }

  onStatusSelect(e:any){
    //console.log(e);
    //console.log(this.FilterControls);
    
  }

  multiSelect_removeItem(item:string, field:string){
    let Ind = this.filter_controls.findIndex(x => x.field_label == field);
    if(Ind !== -1){
      this.filter_controls[Ind].field_value = this.filter_controls[Ind].field_value.filter((x:any) => x !== item);
    }
  }

  async resetFilter(){
    await this.filter_controls.forEach(item => {
      
      item.field_value = undefined;

      if(item.sub_fields && item.sub_fields.length > 0)
      {
        item.sub_fields.forEach((SubField:any) => {
          SubField.field_value = undefined;
        });
      }
      
    });
  }

  saveFilter() {
    let Ind = this.filter_controls.findIndex(x => x.field_label == "Saved Filters");
    
    if (Ind !== -1) {
      this.filter_controls[Ind].field_data.push({ text: this.filter_name, value: this.filter_name });
    }
    this.filter_name = "";
    this.saveFilterDialog_visibility = false;
  }

  applyFilter(){
    if(this.filter_controls.length){
      let filter_obj:any = "";
      this.filter_controls.forEach(cntrl => {
        if (cntrl.field_value !== undefined && cntrl.field_value !== '' && cntrl.field_value !== null) {
          if (
            cntrl.field_type == 'date-range' &&
            cntrl.field_value[0] &&
            cntrl.field_value[1]
          ) {
            this.error = false
            filter_obj = filter_obj + `&${cntrl.key}=${cntrl.field_value}`;
          } else if (
            cntrl.field_type == 'date-range' &&
            cntrl.field_value[0] &&
            !cntrl.field_value[1]
          ) {
            this.error = true
            return;
          } else {
            this.error = false
            filter_obj = filter_obj + `&${cntrl.key}=${cntrl.field_value}`;
          }
        }
      });
      if(!this.error){
        this.OnApplyFilter.emit(filter_obj);
        this.hide();
      }
    }
  }
}
