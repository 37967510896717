import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, RendererStyleFlags2, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'grid-action-button',
  templateUrl: './grid-action-button.component.html',
  styleUrls: ['./grid-action-button.component.scss']
})
export class GridActionButtonComponent implements OnInit {
  elemID: string;
  xPosition: string;
  yPosition: string;
  ShowItems:any[] = [];
  @Input() items:any[] = []; // Accept a list of items as input
  @Input() data: any;
  @Input() disabled: boolean = false;
  isOpen = false; // Local state to manage the dropdown visibility

  @Output() OnSelect: EventEmitter<any> = new EventEmitter();
  @Output() OnButtonClick: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.elemID = this.generateRandomId('actionButton-')
  }

  ngOnInit(): void {
    // var threshold = 500;
    // const dropdown = document.querySelector(`.dropdown-button`) as HTMLElement;
    // const rect = dropdown.getBoundingClientRect();
    // console.dir(rect);
    // this.xPosition = `${rect.x - 155}px`;
    // this.yPosition = `${rect.y > threshold ? rect.y - 150 : rect.y}px`
    
  }

  // Toggle the visibility of the menu
  toggleMenu(e: any): void {
    //console.log(this.data);
    this.isOpen = !this.isOpen;
    this.OnButtonClick.emit();

    setTimeout(() => {
      const dropdownMenu = document.querySelector(`.dropdown-menu`) as HTMLElement;
      if (dropdownMenu instanceof HTMLElement) {
        var threshold = 500;
        
        // Change the top and right positions
        dropdownMenu.style.top = `${e.y > threshold ? e.y - 80 : e.y}px`;  // Adjust the value as needed
        dropdownMenu.style.left = `${e.x - 155}px`; // Adjust the value as needed
      }

      //this.loadMenuItems();
    },100);

  }

  loadMenuItems(){

    let newArr:any[] = [];

    this.items.forEach((item) => {
      if(item?.label == "Move to On Hold" && this.data?.status == 'Not Started'){
        newArr.push({
          label: item.label,
          disabled: false,
        });
      }
      else if(item?.label == "Move to On Hold" && this.data?.status == 'On Hold'){
        newArr.push({
          label: 'Resume',
          disabled: item.disabled,
        });
      }
      else if(item?.label == "Move to Completed" && this.data?.status == 'On Hold'){
        newArr.push({
          label: item.label,
          disabled: false,
        });
      }
    });
    this.ShowItems = [...newArr];

    console.log(this.ShowItems);
    
    // this.items.push({
    //     label: 'Umer',
    //     disabled: false,
    //   })

    
    // console.log(this.items);
    
  }

  generateRandomId(prefix: string = 'id-'): string {
    const randomPart = Math.random().toString(36).substring(2, 9); // Random string from 8 characters
    return `${prefix}${randomPart}`;
  }

  onActionSelect(item:MenuItem) {
    if(!item.disabled){
      this.OnSelect.emit(item);
    }
  }

  //Close the dropdown when clicking outside
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const dropdownMenu = document.querySelector(`.dropdown-menu`) as HTMLElement;
    const clickedInside = (event.target as HTMLElement).closest(`.shis-grid-actiondropdown.${this.elemID}`);
    if (!clickedInside) {
      this.isOpen = false; // Close the dropdown if the click is outside
    }
  }

  ngOnDestroy(): void {
    this.isOpen = false;
  }
}
