import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AddSelectItemDialogComponent } from './components/add-select-item-dialog/add-select-item-dialog.component';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'acv-select',
  templateUrl: './acv-select.component.html',
  styleUrls: ['./acv-select.component.scss']
})
export class AcvSelectComponent implements OnInit, AfterViewInit, AfterContentChecked {

  value: any;
  form_data: any;
  filter_string: string;
  Options:any[];
  options_list:any[];
  OptionLabel: string;
  readOnly: boolean = false;
  @Input() form: FormGroup;
  @Input() ControlName: string;
  @Input() ControlType: string;
  @Input() Required: boolean = false;
  @Input() ControlLabelText: string;
  @Input() PlaceHolderText: string = "Select";
  @Input() OptionValue: string = "id";
  @Input() Filter: boolean = true;
  @Input() Disabled: boolean = true;
  @Input() IsSubmited: boolean = false;
  @Input() AddNewItem: boolean = false;
  @Input() IsLoading: boolean = false;
  @Input() AddNewItemText: string = "Add New Item";
  @Input() AddNewItemModalTitle: string = "Add New Item";
  @Input() AllowItemRemove: boolean = true;
  @Input() AddNewItemAPI: string;
  @Input() LookupAPI: string;
  @Input() showClear: boolean = false;
  @Input() editable: boolean = true;

  @Input('Options') set _Options(value: any) {
    this.Options = (value) ? value : [];
    this.options_list = this.Options;
  }

  @Input('OptionLabel') set _OptionLabel(value: any) {
    this.OptionLabel = (value) ? value : "type";
  }

  @Output() onAddItem: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  @ViewChild("textControl") text: ElementRef;
  @ViewChild('acvdropdown') acv_dropdown_ref:any;
  @ViewChild('AddItemDialog_Ref') AddItemDialog_Ref: AddSelectItemDialogComponent;

  words: number = 0;

    constructor(private confirmationService: ConfirmationService, private cdref: ChangeDetectorRef, private httpService: ApiHttpService,private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.form_data = this.form.value;
    this.options_list = this.Options;
    if(this.editable === false){
      // this.form.controls[this.ControlName].disable();
      this.readOnly = true;
    }else{
      this.readOnly = false;
    }
    // if (this.AddNewItem) {
    //   let opts = this.Options;
    //   opts.unshift({ id: undefined, name: this.AddNewItemText });
    //   this.Options = opts;
    // }

  }

  async reloadLookup(){
    this.IsLoading = true;
    let resp = await this.httpService.get(this.LookupAPI ? `${this.LookupAPI}` : `${this.AddNewItemAPI}`);
    this.IsLoading = false;

    if((resp?.status == 200 || resp?.status == 201) && resp?.data){
      if(resp?.data?.length){
        this.Options = [...resp?.data];
        this.options_list = [...resp?.data];
        this.cdref.detectChanges();
      }
    }
  }

  ngAfterViewInit(): void {
    this.form.controls[this.ControlName].setValue(this.form_data[this.ControlName]);
    this.cdref.detectChanges();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  filterDropdown(val:any){
    this.options_list = this.Options.filter((x:any) => x[this.OptionLabel]?.toLowerCase().includes(this.filter_string?.toLowerCase()) );
  }

  onSelect(e:any){
    this.onChange.emit(e);
  }

  handleOnClear(e:any){
    this.form.controls[this.ControlName].setValue(null);
  }

  onAddNewItem(){
    this.AddItemDialog_Ref.title = this.AddNewItemModalTitle;
    this.AddItemDialog_Ref.api_url = this.AddNewItemAPI;
    this.acv_dropdown_ref?.hide();
    this.AddItemDialog_Ref.show();
    // this.onAddItem.emit(this.ControlName);
  }

  getSelectedLabel(){

    let record = this.options_list.find((x:any) => x.id == this.form.controls[this.ControlName].value);
    if(record){
      return record[this.OptionLabel];
    }
    else{
      return null
    }
  }

  delete(evnt:any){
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      header: 'Delete',
      icon: 'i-warn',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.deleteItem(evnt)
      },
    });
  }

  async deleteItem(e:any){
    const api_url = this.AddNewItemAPI + `/${e.id}`
    let resp = await this.httpService.delete(api_url);
    if(resp?.status == 200){
      this.reloadLookup()
      this.messageService.add({severity:'success', summary:'Success', detail: resp?.message, key: 'app_notiy'});
    }
    else{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: resp?.error?.message, key: 'app_notiy'});
    }
  }

  onUpdateItem(event:any){
    this.AddItemDialog_Ref.title = this.AddNewItemModalTitle;
    this.AddItemDialog_Ref.api_url = this.AddNewItemAPI + `/${event.id}`;
    this.acv_dropdown_ref?.hide();
    this.AddItemDialog_Ref.show(event);
  }
}
