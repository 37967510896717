<p-dialog
  [styleClass]="'popup_historyDetail'"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [style]="{ width: '32%' }"
  [modal]="true"
  [visible]="visibility"
>
  <ng-template pTemplate="header">
    <div
      class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted"
    >
      <span class="fs-16 font-semibold">{{ moduleName | titlecase }} Updated</span>
      <p-button
        icon="pi pi-times"
        (onClick)="hide()"
        styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"
      ></p-button>
    </div>
  </ng-template>

  <div class="history_detail_content_wrapper">
    <ng-container *ngFor="let item of data">
      <div
        *ngFor="let change of item.properties.changes | keyvalue"
      >
        <div class="flex gap-2">
          <div class="col-6">
            <div class="font-poppin fs-12 fw-500 text-color-black">
              {{ change.key | titlecase }} Before Updated:
            </div>
            <div class="font-poppin fs-16 fw-500 text-primary">
              {{ change.value.old }}
            </div>
          </div>
          <div class="col-6">
            <div class="font-poppin fs-12 fw-500 text-color-black">
              {{ change.key | titlecase }} After Updated:
            </div>
            <div class="font-poppin fs-16 fw-500 text-primary">
              {{ change.value.new }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</p-dialog>
