<p-dialog [styleClass]="'acv_dialog'" [draggable]="false" [resizable]="false" [closable]="false" [style]="{'width': '30%'}" [modal]="true"
    [visible]="show_Dialog">

    <ng-template pTemplate="header">
        <div class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted">
            <span class="text-xl font-bold">{{ updateItemValue ? title.replace("New", "Update") : title }}
            </span>
            <p-button icon="pi pi-times" (onClick)="hide()"
                styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"></p-button>
        </div>
    </ng-template>

    <div class="dialog_content_wrapper">
        <form [formGroup]="addItemForm" *ngIf="addItemForm">
            <div class="grid m-0">
                <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                    <acv-text-input [form]="addItemForm" ControlName="name" [ControlLabelText]="'Name'"
                        [PlaceHolderText]="'Enter Name'" [Required]="true"
                        [IsSubmited]="is_form_submit" [ShowWordCount]="false"></acv-text-input>
                </div>
            </div>
        </form>
    </div>

    <ng-template pTemplate="footer">
        <p-button
        (onClick)="handleCancelConfirm()"
        type="button"
        label="Cancel"
        class="p-button-text elevated-btn secondary mr-2"
      ></p-button>
      <p-button
        type="button"
        [label]="updateItemValue ? 'Update' : 'Add'" 
        (onClick)="handleSubmitForm(updateItemValue ? 'Update' : 'Add')"
        [loading]="isLoading"
        class="p-button-text elevated-btn primary mr-2"
      ></p-button>
    </ng-template>
</p-dialog>