import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';

@Component({
  selector: 'acv-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent
  implements OnInit, AfterViewInit, AfterContentChecked {
  value: any;
  wordCount: any;
  form_data: any;
  Options: any[];
  ControlNameCode: any = '+52';
  ControlName_display: any = '';
  cc_loader: boolean = true;
  cc_list: any[] = [];
  @Input() readOnly: boolean = false;
  @Input() editable: boolean = true;
  @Input() form: FormGroup;
  @Input() ControlName: string;
  @Input() ControlType: string = 'text';
  @Input() Required: boolean = false;
  @Input() ControlLabelText: string;
  @Input() PlaceHolderText: string = 'Enter Here';
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() selectionMode: string = 'single';
  @Input() IconClass: string;
  @Input() IconPosition: string;
  @Input() ClassList: string;
  @Input() ShowWordCount: boolean = true;
  @Input() minCharLength: any = null;
  @Input() characterLimit: number = 50;
  @Input() IsSubmited: boolean = false;
  @Input() PlaceHolderTextCode: string = '+1';
  @Input() IsLoading: boolean = false;
  @Input() ExemptMaxLength: boolean = false;
  @Input() radioList: any[] = [];
  @Input() noSpace: boolean = false;
  @Input('Options') set _Options(value: any) {
    this.Options = value ? value : [];
  }
  @Input() PatternErrorMessage: string;

  @ViewChild('textControl') text: ElementRef;

  @HostListener('change', ['$event'])
  onChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (this.ControlType === 'phoneCode') {
      this.form.controls[this.ControlName]?.setValue(inputElement.value ? `${this.ControlNameCode}${inputElement.value}` : "");
    }
  }

  words: number = 0;
  phone_value: any;

  constructor(
    private cdref: ChangeDetectorRef,
    private httpService: ApiHttpService
  ) { }

  ngOnInit(): void {
    this.form_data = this.form.value;
    if (this.ControlType == 'phoneCode') {
      this.loadCountryCodes();
    }
    if(this.editable === false){
      this.form.controls[this.ControlName].disable();
    }
  }

  setPhoneControlValue(value?: string) {
    if (this.ControlType == 'phoneCode') {
      if (this.form_data[this.ControlName] !== null) {
        this.splitPhoneNumber();
      } else {
        this.form.controls[this.ControlName].setValue(value);
        this.splitPhoneNumber();
      }
    } else {
      this.form.controls[this.ControlName].setValue(
        this.form_data[this.ControlName]
      );
    }
  }

  splitPhoneNumber() {
    this.ControlNameCode =
      this.form.controls[this.ControlName]?.value.split('(')[0];
    this.ControlName_display =
      '(' + this.form.controls[this.ControlName]?.value.split('(')[1];
  }

  // setPhoneControlValue(value?: string) {
  //   if (this.ControlType == 'phoneCode') {
  //     if (this.form_data[this.ControlName] !== null) {
  //       this.splitPhoneNumber();
  //     } else {
  //       this.form.controls[this.ControlName].setValue(value);
  //       this.splitPhoneNumber();
  //     }
  //   } else {
  //     this.form.controls[this.ControlName].setValue(
  //       this.form_data[this.ControlName]
  //     );
  //   }
  // }

  preventSpace(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }

  ngAfterViewInit(): void {
    if (this.noSpace) {
      this.text.nativeElement.addEventListener('keydown', this.preventSpace);
    }
    this.form.controls[this.ControlName].setValue(
      this.form_data[this.ControlName]
    );
    if (
      this.ControlType == 'phoneCode' &&
      this.form.controls[this.ControlName].value
    ) {
      this.splitPhoneNumber();
    }

    if (this.ShowWordCount) {
      this.wordCounter();
    }
    this.cdref.detectChanges();
  }

  // ngAfterViewInit(): void {
  //   this.setPhoneControlValue();
  // }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  async loadCountryCodes() {
    this.cc_loader = true;
    let resp = await this.httpService.get('/countries');
    this.cc_loader = false;
    if (resp.status == 200 || resp.status == 201) {
      this.cc_list = resp?.data;
    }
  }
  
  onKeyPress(event: KeyboardEvent) {
    // Added the below code to prevent the user from entering any special characters
    // Currently it is for zip_code field only
    if (this.ControlName === 'zip_code') {
      const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);
  
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
    if (this.ControlName === 'tax_id') {
      const pattern = /[a-zA-Z0-9\-]/;
      const inputChar = String.fromCharCode(event.charCode);
  
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }

  wordCounter() {
    //alert(this.text.nativeElement.value)
    this.wordCount = this.text
      ? this.text?.nativeElement?.value.split(/\s+/)
      : 0;
    this.words = this.text?.nativeElement?.value.length;
  }

  onPhoneCodeChange(e: any) {
    this.ControlNameCode = e.value;
    this.form.controls[this.ControlName]?.setValue(this.ControlName_display ? `${this.ControlNameCode}${this.ControlName_display}` : "");
  }

  getSelectedCountry(country_code: string) {
    return this.cc_list.find((x: any) => x.phonecode == country_code);
  }
}
