import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { DownloadConfirmedPopupComponent } from '../download-confirmed-popup/download-confirmed-popup.component';

@Component({
  selector: 'import-popup',
  templateUrl: './import-popup.component.html',
  styleUrls: ['./import-popup.component.scss'],
})
export class ImportPopupComponent {
  import_stage: string = 'ask_to_upload';
  value: number = 0;
  allowed_formats: string[] = ['csv', 'xlsx'];
  maxFileSize: number;
  file: string[];
  file_name: string = '';
  file_extension: string = '';
  show_ImportDialog: boolean = false;
  dialog_header: string = 'Upload CSV';
  importError_Visible: boolean = false;
  genericError_Visible: boolean = false;
  importError_Message: string = '';
  Is_ValidFile: boolean = false;
  Is_OrderDetail: boolean = false;
  // global interval
  interval: any;

  @ViewChild('fubauto') fileUpload: any;
  @ViewChild('confirmedUpload_ref')
  confirmedUpload_ref: DownloadConfirmedPopupComponent;

  @Input() moduleName: string = '';
  isSuccessful: boolean = false;
  errors: any;
  error_Visible: boolean = false;
  @Input('MaxFileSize') set _MaxFileSize(value: any) {
    this.maxFileSize = value ? value : 2000000;
  }

  @Output() relaodGrid: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogService: DialogService,
    private messageService: MessageService,
    private httpService: ApiHttpService
  ) {}

  onFileDrop(evnt: any) {
    if (evnt && this.value == 0) {
      this.file = evnt[0].file;
      this.file_extension = evnt[0].file.name.split('.')[1];
      this.file_name = evnt[0].file.name;

      if (this.allowed_formats.includes(this.file_extension)) {
        if (
          evnt[0].file.size == this.maxFileSize ||
          evnt[0].file.size < this.maxFileSize
        ) {
          this.import_stage = 'upload_progress';
          this.Is_ValidFile = true;
          this.checkfile(this.file)
        } else {
          this.Is_ValidFile = false;
          this.importError_Visible = true;
          this.importError_Message =
            'The specified file ' +
            this.file_name +
            ' exceeds the maximum upload size.';
        }
      } else {
        this.Is_ValidFile = false;
        this.importError_Message =
          'Invalid File! This file format is not allowed';
        this.importError_Visible = true;
      }
    } else {
      this.genericError_Visible = true;
      this.importError_Message =
        'Action not allowed! A file is still in progress';
    }
  }

  onBasicUploadAuto(evnt: any) {
    this.file = evnt.currentFiles[0];
    this.file_extension = evnt.currentFiles.length ? evnt.currentFiles[0].name.split('.')[1] : '';
    this.file_name = evnt.currentFiles.length ? evnt.currentFiles[0].name : '';
    if (this.allowed_formats.includes(this.file_extension)) {
      if (
        evnt.currentFiles[0].size == this.maxFileSize ||
        evnt.currentFiles[0].size < this.maxFileSize
      ) {
        this.import_stage = 'upload_progress';
        this.Is_ValidFile = true;
        this.checkfile(this.file)
      } else {
        this.Is_ValidFile = false;
        this.importError_Visible = true;
        this.importError_Message =
          'The specified file ' +
          this.file_name +
          ' exceeds the maximum upload size.';
      }
    } else {
      this.Is_ValidFile = false;
      this.importError_Message =
        'Invalid File! This file format is not allowed';
      this.importError_Visible = true;
      //this.messageService.add({key: 'app_notiy', severity:'error', summary: 'Invalid File', detail: 'This file is not allowed', sticky: false});
    }
  }
  reloadGridData() {
    this.relaodGrid.emit(); // Emit event to parent
  }
  async checkfile(file: any){
    const formData = new FormData();
    formData.append('import_file', file);
    formData.append('is_preview', '1')
    const resp = await this.httpService.post(
      `/${this.moduleName}/import`,
      formData
    );
    if (resp?.status == 200 || resp?.status == 201) {
      resp?.data?.errors?.length >= 1 ? this.error_Visible = true : this.error_Visible = false;
      this.errors = resp?.data?.errors
    }else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: resp?.message,
        key: 'app_notiy',
      });
      this.Is_ValidFile = false
    }
  }

  async submit_file(file: any) {
    console.log(file, 'file');
    const formData = new FormData();
    formData.append('import_file', file);
    const resp = await this.httpService.post(
      `/${this.moduleName}/import`,
      formData
    );
    if (resp?.status == 200 || resp?.status == 201) {
      this.confirmedUpload_ref.show();
      this.reloadGridData();
      this.error_Visible = false
      this.Is_ValidFile = false
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: resp?.error?.message,
        key: 'app_notiy',
      });
    }
    this.hide();
  }

  // cancel upload
  uploadCancel() {
    this.Is_ValidFile = false;
    this.error_Visible = false;
    this.import_stage = 'ask_to_upload';
    this.value = 0;
    this.genericError_Visible = false;
    clearInterval(this.interval);
    this.fileUpload.clear();
  }

  // cancel popup
  cancel() {
    this.dialogService.dialogComponentRefMap.forEach((dialog) => {
      dialog.destroy();
    });
  }

  show() {
    this.show_ImportDialog = true;
  }

  hide() {
    this.show_ImportDialog = false;
    this.importError_Message = '';
    this.Is_ValidFile = false;
    this.error_Visible = false;
    this.value = 0;
    this.genericError_Visible = false;
    clearInterval(this.interval);
    this.importError_Visible = false;
    this.import_stage = 'ask_to_upload';
    this.dialog_header = 'Import File';
    this.fileUpload.clear();
  }

  async downloadTemplate() {
    const resp = await this.httpService.post(`/${this.moduleName}/export`, {
      is_for_template: 1,
    });
    if (resp?.status == 200) {
      window.location.href = resp?.data?.link;
      this.messageService.add({
        severity: 'customSuccess',
        icon: 'i-check-circle',
        summary: 'Success',
        detail: resp?.message,
        key: 'app_notiy',
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: resp?.message,
        key: 'app_notiy',
      });
    }
  }
}
