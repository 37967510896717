import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';

@Component({
  selector: 'acv-grid-count-dialog',
  templateUrl: './grid-count-dialog.component.html',
  styleUrls: ['./grid-count-dialog.component.scss']
})
export class GridCountDialogComponent {
  actionForm: any;
  title: string = "";
  tripId:number;
  show_Dialog: boolean = false;
  dialog_header: string = "Milestone Associated";
  complete_row_data: any;
  trip_detail:any;
  data: any[] = [];
  data_type: string = "list";
  view_mode: string = "grid";
  is_form_submit: boolean = false;
  active_panelIndex:number = 0;
  gridActions: MenuItem[] = [
    { label: 'Edit', disabled: false },
  ];
  hours_arr: any = [
    { name: '00' },
    { name: '01' },
    { name: '02' },
    { name: '03' },
    { name: '04' },
    { name: '05' },
    { name: '06' },
    { name: '07' },
    { name: '08' },
    { name: '09' },
    { name: '10' },
    { name: '11' },
    { name: '12' },
    { name: '13' },
    { name: '14' },
    { name: '15' },
    { name: '16' },
    { name: '17' },
    { name: '18' },
    { name: '19' },
    { name: '20' },
    { name: '21' },
    { name: '22' },
    { name: '23' },
  ];
  mins_arr: any = [
    { id: '00', name: '00 min' },
    { id: '15', name: '15 min' },
    { id: '30', name: '30 min' },
    { id: '45', name: '45 min' },
  ];
  center: google.maps.LatLngLiteral = { lat: 37.7749, lng: -122.4194 }; // Default center
  zoom = 10; // Default zoom level
  markerPosition: google.maps.LatLngLiteral = { lat: 37.7749, lng: -122.4194 }; // Default marker position
  markerOptions: google.maps.MarkerOptions = { draggable: true }; // Marker options
  complete_address:string = "";

  gridColumns:any[] = [];
  action_data:any;

  @Output() Submit_Clicked: EventEmitter<any> = new EventEmitter();

  constructor(public dialogService: DialogService, private fb: FormBuilder, private httpService: ApiHttpService, private messageService: MessageService, private _activatedRoute: ActivatedRoute,) {}

  cancel() {
    this.dialogService.dialogComponentRefMap.forEach(dialog => {
      dialog.destroy();
    });
  }


  async show() {
    this.complete_address = '';
    this.show_Dialog = true;
    this.tripId = parseInt(this._activatedRoute.snapshot.paramMap.get('id'));
    let resp = await this.httpService.get(`/trip/${this.tripId}`);
    this.trip_detail = resp.status == 200 ? resp?.data : null;

    console.log(resp?.data.status);
    
    
    if(this.data_type == "panel"){
      this.gridColumns = [
        { field: 'name', header: 'Actions', visible: true, isClickable: false, align: 'left', colWidth: '150px' },
        { field: 'completed_at', header: 'Complete Date & Time', fieldType: 'date', format: 'MM/dd/YYYY HH:mm', visible: true, isClickable: false, align: 'left', colWidth: '100px' },
        { field: 'destination', header: 'Location', visible: true, isClickable: false, align: 'left', colWidth: '100px' },
        { field: 'manual_completed_at', header: 'Actual Complete Date & Time', fieldType: 'date', format: 'MM/dd/YYYY HH:mm', visible: true, isClickable: false, align: 'left', colWidth: '200px' },
        { field: 'manual_destination', header: 'Actual Location', visible: true, isClickable: false, align: 'left', colWidth: '250px' },
        { field: 'status', header: '', visible: false, isClickable: false, align: 'left', colWidth: '50px' },
      ]
    }

    //console.log(this.complete_row_data);
    
    //console.log(this.data);
  }

  hide() {
    this.show_Dialog = false;
    this.view_mode = "grid";
  }

  onGridAction(event: any) {
    this.action_data = event.data;
    console.log("Grid Action", event);
    if(this.action_data.manual_location){
      this.center.lat = parseFloat(this.action_data.manual_location.split(',')[0]);
      this.center.lng = parseFloat(this.action_data.manual_location.split(',')[1]);
      this.markerPosition.lat = parseFloat(this.action_data.manual_location.split(',')[0]);
      this.markerPosition.lng = parseFloat(this.action_data.manual_location.split(',')[1]);
    } else if (this.action_data.location) {
      this.center.lat = parseFloat(this.action_data.location.split(',')[0]);
      this.center.lng = parseFloat(this.action_data.location.split(',')[1]);
      this.markerPosition.lat = parseFloat(this.action_data.location.split(',')[0]);
      this.markerPosition.lng = parseFloat(this.action_data.location.split(',')[1]);
    }
    if(this.action_data.manual_destination){
      this.complete_address = this.action_data.manual_destination;
    } else if(this.action_data.destination) {
      this.complete_address = this.action_data.destination;
    }
    this.initializeForm();
    this.view_mode = "map";
    setTimeout(() => {
      this.LoadMap();
    }, 2000)
  }

  LoadMap() {
    const input = document.getElementById('autocomplete') as HTMLInputElement;
  
    const autocomplete = new google.maps.places.Autocomplete(input, {
      types: [], // Allow all types of places
      componentRestrictions: { country: ['us', 'mx'] }, // Restrict to US and Mexico
    });
  
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const location = place.geometry.location;
        this.center = { lat: location.lat(), lng: location.lng() };
        this.markerPosition = { lat: location.lat(), lng: location.lng() };
        this.actionForm.controls['manual_location'].setValue(`${location.lat()}, ${location.lng()}`);
        this.actionForm.controls['manual_address'].setValue(place.formatted_address);
      }
    });
  
    // Add listener for manual input to handle coordinates
    input.addEventListener('change', () => {
      const inputValue = input.value.trim();
      const coordinatePattern = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/;
      if (coordinatePattern.test(inputValue)) {
        const [lat, lng] = inputValue.split(',').map(Number);
        const location = new google.maps.LatLng(lat, lng);
        this.center = { lat, lng };
        this.markerPosition = { lat, lng };
        this.actionForm.controls['manual_location'].setValue(`${lat}, ${lng}`);
        this.getAddress(lat, lng);
      }
    });
  }

  // Handle marker drag end
  onMarkerDragEnd(event: any) {
    if (event.latLng) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.getAddress(event.latLng.lat(), event.latLng.lng());
      this.actionForm.controls['manual_location'].setValue(`${event.latLng.lat()}, ${event.latLng.lng()}`);
      //console.log(event);
      
      //console.log('Marker position:', this.markerPosition);
    }
  }

  initializeForm() {
    this.actionForm = this.fb.group({
      completed_at: [this.action_data?.manual_completed_at ? new Date(this.action_data.manual_completed_at) : null, Validators.compose([Validators.required])],
      manual_completed_at: [null],
      manual_completed_time: [null],
      complete_hr: [null, Validators.compose([Validators.required])],
      complete_min: ['00', Validators.compose([Validators.required])],
      manual_location: [this.action_data?.manual_location ? this.action_data?.manual_location : `${this.center.lat}, ${this.center.lng}`],
      manual_address: [this.action_data?.manual_destination ? this.action_data?.manual_destination : this.getAddress(this.center.lat, this.center.lng)]
    });

    if(this.action_data?.manual_completed_at){
      this.setTime();
    }

  }

  setTime(){
    let time = this.action_data.manual_completed_at.split(' ')[1];
    this.actionForm.controls['complete_hr'].setValue(time.split(':')[0]);
    this.actionForm.controls['complete_min'].setValue(time.split(':')[1]);
  }

  getAddress(lat: number, lng: number): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        this.complete_address = results[0].formatted_address;
        this.actionForm.controls['manual_address'].setValue(results[0].formatted_address);
      } else {
        console.error('Geocoding error:', status);
      }
    });
  }

  async submit(){
    if(this.actionForm.valid){
      this.actionForm.controls['manual_completed_time'].setValue(`${this.actionForm.controls['complete_hr'].value}:${this.actionForm.controls['complete_min'].value}`);
      this.actionForm.controls['manual_completed_at'].setValue(moment(this.actionForm.controls['completed_at'].value).format('MM/DD/YYYY'));
      delete this.actionForm.value.completed_at;
      let resp = await this.httpService.post(`/trip-detail-action/${this.action_data?.id}/update-details`, this.actionForm.value);
      if (resp?.status == 200) {
        this.reloadTrip();
        //this.driver_types = resp?.data;
      }
      else{
        this.messageService.add({
          severity: 'error',
          summary: 'Failed',
          detail: resp?.error?.message,
          key: 'app_notiy',
        });
      }
    }
  }

  async reloadTrip(){
    let resp = await this.httpService.get(`/trip/${this.tripId}`);
    if (resp.status == 200) {
      this.data = resp?.data.detail.find((x:any) => x.leg_id == this.complete_row_data.leg_id).milestones;
      this.view_mode = "grid";
      this.messageService.add({
        severity: 'customSuccess',
        icon: 'i-check-circle',
        summary: 'Success',
        detail: 'Action Updated Successfully',
        key: 'app_notiy',
      });
    }
    else{
      this.messageService.add({
        severity: 'error',
        summary: 'Failed',
        detail: resp?.error?.message,
        key: 'app_notiy',
      });
    }
  }

  onPanelColapseChange(e:any, ind:number){
    this.active_panelIndex = ind;
  }
}
