<p-dialog [styleClass]="'popup_import'" [draggable]="false" [resizable]="false" [closable]="false"
    [style]="{'width': '70%'}" [modal]="true" [visible]="show_Dialog">
    <ng-template pTemplate="header">
        <div class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted">
            <span class="text-xl font-bold">{{title}}</span>
            <p-button icon="pi pi-times" (onClick)="hide()"
                styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"></p-button>
        </div>
    </ng-template>
    <div class="col-12 md:col-12 lg:col-12 xl:col-12 p-0 mb-3" style="position: relative;">
        <input #autocompleteInput id="autocomplete" type="text" [(ngModel)]="complete_address" [ngModelOptions]="{standalone: true}" placeholder="Search location" class="autocomplete-input" />
        <google-map height="300px" width="100%" [center]="center" [zoom]="zoom">
            <map-marker [position]="markerPosition" [options]="markerOptions" [draggable]="true"
                (mapDragend)="onMarkerDragEnd($event)"></map-marker>
        </google-map>
    </div>
    <ng-template pTemplate="footer">
        <p-button type="button" [label]="'Select'"
            class="p-button-text elevated-btn primary mr-2" (click)="submit()"></p-button>
    </ng-template>
</p-dialog>