import { Component, OnInit } from '@angular/core';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { ProfileService } from 'src/app/core/services/profile.service';

@Component({
  selector: 'app-page-notfound',
  templateUrl: './page-notfound.component.html',
  styleUrls: ['./page-notfound.component.scss']
})
export class PageNotfoundComponent implements OnInit {

  constructor(private httpService: ApiHttpService, private profileService: ProfileService,){ this.LoadUserInfo(); console.log(this.profileService.getuserProfileData());
   }
  ngOnInit(): void {
    
  }

  async LoadUserInfo(){
    const resp = await this.httpService.get('/me', {
      token: this.profileService.gettoken(),
    });
    if (resp.status == 200) {
      this.profileService.setuserProfileData(resp.data);
      this.profileService.setPermissions();
    }
  }
}
