import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MenuItem, MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiHttpService } from '../../services/apiHttpcall.service';
import { DataResolverService } from '../../services/data-resolver.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  overlayVisible: boolean = false;
  initials_char: string = '';
  items: MenuItem[] = [];
  userInfo: any = {};
  profile_Image: any;
  profile_data = {
    Image_URL: '',
  };
  avatarImage: string = '/assets/images/avatars/profile_avatar.jpg';

  constructor(
    private _location: Location,
    private confirmationService: ConfirmationService,
    private router: Router,
    private UILoader: NgxSpinnerService,
    private httpService: ApiHttpService,
    private messageService: MessageService,
    private profileService: ProfileService,
    private dataResolver: DataResolverService
  ) {}

  ngOnInit() {
    this.items = [
      { label: 'Profile', icon: 'pi i-user', routerLink: ['/profile'] },
      {
        label: 'Logout',
        icon: 'pi i-sign-out',
        command: () => {
          this.logout();
        },
      },
    ];
    this.userInfo = this.profileService.getuserProfileData();

    if(this.userInfo == undefined){
      this.LoadUserInfo();
      return;
    }
    
    // this.profile_data.Image_URL = this.userInfo.image_url;
    const fullName = this.userInfo.first_name + ' ' + this.userInfo.last_name;
    this.initials_char = this.getNameInitials(fullName);
    this.dataResolver.set_UserProfile(this.profile_data);
    this.dataResolver.get_UserProfile().subscribe((profileData) => {
      this.profile_Image = profileData.Image_URL;
    });
  }

  async LoadUserInfo(){
    const resp = await this.httpService.get('/me', {
      token: this.profileService.gettoken(),
    });
    if (resp.status == 200) {
      this.profileService.setuserProfileData(resp.data);
      this.profileService.setPermissions();

      this.userInfo = resp.data;
      const fullName = resp.data.first_name + ' ' + resp.data.last_name;
      this.initials_char = this.getNameInitials(fullName);
      this.dataResolver.set_UserProfile(resp.data);
      this.dataResolver.get_UserProfile().subscribe((profileData) => {
        this.profile_Image = profileData.Image_URL;
      });
    }
  }

  goBack() {
    this._location.back();
  }

  logout() {
    this.confirmationService.confirm({
      header: 'Logout',
      message: 'Are you sure you want to Logout?',
      icon: 'i-warn',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.logoutApiCall();
      },
    });
  }

  profile(){
    this.router.navigate(['profile']);
  }

  // get name initials
  getNameInitials(value: any) {
    if (value) {
      var names = value?.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    }
  }

  async logoutApiCall() {
    this.UILoader.show();
    let resp = await this.httpService.post('/logout', {
      token: this.profileService.gettoken(),
    });

    if (resp.status == 200) {
      this.router.navigate(['/login']);
      localStorage.removeItem('access_token');
      this.UILoader.hide();
    } else {
      // this.messageService.add({ severity: 'error', summary: 'Failed', detail: resp.error.error.detail, key: 'app_notiy' });
      this.UILoader.hide();
    }
  }
}
