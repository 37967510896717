<p-dialog [styleClass]="'popup_suborder'" [draggable]="false" [resizable]="false" [closable]="false" [style]="{'width': '80%'}"
    [modal]="true" [visible]="show_Dialog">

    <ng-template pTemplate="header">
        <div class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted">
            <span class="text-xl font-bold">Add Line</span>
            <p-button icon="pi pi-times" (onClick)="hide()"
                styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"></p-button>
        </div>
    </ng-template>

    <acv-grid [GridColumns]="line_itemsColumns" [FreezeActionColumn]="false" [GridDataSource]="line_items"
        [AllowSelection]="true" [AllowHeaderSelection]="false" [ShowRowGroup]="false" [AllowSorting]="true" [AllowPagination]="false"
        [AllowColumnReOrdering]="false" [ShowLineLevelAction]="false" [ShowAttachmentIcon]="false"
        [ShowDeleteIcon]="false" #grid_Ref></acv-grid>

    <ng-template pTemplate="footer">
        <div class="dialog_footer mt-4">
            <div class="flex align-items-center justify-content-between">
                <div class="flex align-items-center">
                    <p class="control_label mr-2">Quantity</p>
                    <input type="text" pInputText [placeholder]="'Enter Qty'" />
                </div>
                <div>
                    <p-button type="button" label="Submit" class="p-button-text elevated-btn primary mr-2" (onClick)="submit()"></p-button>
                    <p-button type="button" label="Cancel" class="p-button-text elevated-btn secondary"
                        [style]="{'color':'black'}" (click)="hide()"></p-button>
                </div>
            </div>
        </div>
    </ng-template>

    
</p-dialog>