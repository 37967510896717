import { Component, Input, ViewChild } from '@angular/core';
import { HistoryDetailComponent } from './components/history-detail/history-detail.component';
interface Change {
  old: string;
  new: string;
}
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})

export class HistoryComponent {
  @ViewChild('historyDetail_Ref') historyDetail_Ref: HistoryDetailComponent;
  @Input() moduleName: string = '';
  @Input() historyData: any;

  visibility: boolean = false;
  events: any;
  constructor() {}

  ngOnChanges() {
    this.events = this.historyData;
  }

  show() {
    this.visibility = true;
  }

  viewAllDialog() {
    this.historyDetail_Ref.show();
  }

  hide() {
    this.visibility = false;
  }
  getChangeValues(change: any) {
    return change.value as { new: string, old: string };
  }

  hasUpdatedEvent(): boolean {
    return this.events.some((item:any) => item.event === 'updated');
  }
}
