import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';
import { DividerModule } from 'primeng/divider';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { BadgeModule } from 'primeng/badge';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { TimelineModule } from 'primeng/timeline';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TagModule } from 'primeng/tag';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ChipModule } from 'primeng/chip';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ChipsModule } from 'primeng/chips';
import { DragDropModule } from 'primeng/dragdrop';

import { PageHeaderComponent } from './page-header/page-header.component';
import { ACVGridComponent } from './acv-grid/acv-grid.component';
import { GridTabComponent } from './grid-tab/grid-tab.component';
import { PopupGridComponent } from './popup-grid/popup-grid.component';
import { ImportPopupComponent } from './import-popup/import-popup.component';
import { ExportPopupComponent } from './export-popup/export-popup.component';
import { DetailSectionComponent } from './detail-section/detail-section.component';
import { DragndropFilesDirective } from './directives/dragndrop-files.directive';
import { DefineMarginComponent } from './define-margin/define-margin.component';
import { NotesComponent } from './notes/notes.component';
import { FormViewComponent } from './form-view/form-view.component';
import { SideFiltersComponent } from './side-filters/side-filters.component';
import { DetailViewComponent } from './detail-view/detail-view.component';
import { HistoryComponent } from './history/history.component';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { CreateSubOrderPopupComponent } from './create-sub-order-popup/create-sub-order-popup.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HighlighterPipe } from '../core/pipes/highlighter.pipe';
import { TextInputComponent } from './text-input/text-input.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { MultiselectDropdownComponent } from './multiselect-dropdown/multiselect-dropdown.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { OtpInputComponent } from './otp-input/otp-input.component';
import { AcvSelectComponent } from './acv-select/acv-select.component';
import { AddSelectItemDialogComponent } from './acv-select/components/add-select-item-dialog/add-select-item-dialog.component';
import { GridCountDialogComponent } from './acv-grid/components/grid-count-dialog/grid-count-dialog.component';
import { UploadPopupComponent } from '../shared/upload-popup/upload-popup.component';
import { PopupChipsComponent } from '../shared/popup-chips/popup-chips.component';
import { DownloadConfirmedPopupComponent } from './download-confirmed-popup/download-confirmed-popup.component';
import { HistoryDetailComponent } from './history/components/history-detail/history-detail.component';
import { ReasonDialogComponent } from '../features/manage-trips/trip-requests/components/reason-dialog/reason-dialog.component';
import { AcvDragDropComponent } from './acv-drag-drop/acv-drag-drop.component';
import { AppHeaderComponent } from '../core/components/app-header/app-header.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GridActionButtonComponent } from './acv-grid/components/grid-action-button/grid-action-button.component';
import { AcvPinLocationComponent } from './acv-pin-location/acv-pin-location.component';
import { AcvLocationDialogComponent } from './acv-pin-location/components/acv-location-dialog/acv-location-dialog.component';

@NgModule({
  declarations: [
    PageHeaderComponent,
    ACVGridComponent,
    GridTabComponent,
    PopupGridComponent,
    ImportPopupComponent,
    ExportPopupComponent,
    DetailSectionComponent,
    DragndropFilesDirective,
    DefineMarginComponent,
    NotesComponent,
    FormViewComponent,
    SideFiltersComponent,
    DetailViewComponent,
    HistoryComponent,
    ScrollSpyDirective,
    CreateSubOrderPopupComponent,
    UploadPopupComponent,
    PopupChipsComponent,
    NotFoundComponent,
    HighlighterPipe,
    TextInputComponent,
    TextAreaComponent,
    MultiselectDropdownComponent,
    PasswordInputComponent,
    OtpInputComponent,
    AcvSelectComponent,
    AcvPinLocationComponent,
    AddSelectItemDialogComponent,
    GridCountDialogComponent,
    DownloadConfirmedPopupComponent,
    HistoryDetailComponent,
    ReasonDialogComponent,
    AcvDragDropComponent,
    AppHeaderComponent,
    GridActionButtonComponent,
    AcvLocationDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    DividerModule,
    PanelMenuModule,
    SplitButtonModule,
    ChipModule,
    OverlayPanelModule,
    CheckboxModule,
    TableModule,
    ScrollPanelModule,
    BreadcrumbModule,
    TabViewModule,
    CardModule,
    PaginatorModule,
    InputTextModule,
    CalendarModule,
    ConfirmDialogModule,
    BadgeModule,
    DynamicDialogModule,
    PanelModule,
    DialogModule,
    FileUploadModule,
    ProgressBarModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    DropdownModule,
    MultiSelectModule,
    TimelineModule,
    InputTextareaModule,
    InputSwitchModule,
    AutoCompleteModule,
    TagModule,
    InputMaskModule,
    InputNumberModule,
    RadioButtonModule,
    RoundProgressModule,
    ChipsModule,
    DragDropModule,
    GoogleMapsModule,
    SplitButtonModule 
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    DividerModule,
    PanelMenuModule,
    SplitButtonModule,
    ChipModule,
    OverlayPanelModule,
    CheckboxModule,
    TableModule,
    ScrollPanelModule,
    BreadcrumbModule,
    TabViewModule,
    PageHeaderComponent,
    ACVGridComponent,
    GridTabComponent,
    CardModule,
    PaginatorModule,
    InputTextModule,
    CalendarModule,
    ConfirmDialogModule,
    BadgeModule,
    PopupGridComponent,
    ImportPopupComponent,
    ExportPopupComponent,
    DetailSectionComponent,
    FileUploadModule,
    ProgressBarModule,
    ToastModule,
    DialogModule,
    DefineMarginComponent,
    NotesComponent,
    MessagesModule,
    MessageModule,
    AcvPinLocationComponent,
    FormViewComponent,
    SideFiltersComponent,
    DropdownModule,
    MultiSelectModule,
    DetailViewComponent,
    HistoryComponent,
    InputTextareaModule,
    InputSwitchModule,
    PanelModule,
    CreateSubOrderPopupComponent,
    NotFoundComponent,
    HighlighterPipe,
    AutoCompleteModule,
    TagModule,
    TextInputComponent,
    TextAreaComponent,
    MultiselectDropdownComponent,
    PasswordInputComponent,
    OtpInputComponent,
    AcvSelectComponent,
    UploadPopupComponent,
    PopupChipsComponent,
    InputMaskModule,
    InputNumberModule,
    RadioButtonModule,
    DownloadConfirmedPopupComponent,
    RoundProgressModule,
    ChipsModule,
    ReasonDialogComponent,
    AcvDragDropComponent,
    AppHeaderComponent,
  ],
  entryComponents: [],
  providers: [HighlighterPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class SharedModule { }