<p-dialog [styleClass]="'popup_import'" [draggable]="false" [resizable]="false" [closable]="false" [style]="data_type == 'panel' ? {'width': '70%'} : {'width': '30%'}" [modal]="true"
    [visible]="show_Dialog">

    <ng-template pTemplate="header">
        <div class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted">
            <span class="text-xl font-bold">{{title}}</span>
            <p-button icon="pi pi-times" (onClick)="hide()"
                styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"></p-button>
        </div>
    </ng-template>

    <div class="import_content_wrapper">
        <acv-grid *ngIf="data_type == 'grid'" [GridColumns]="gridColumns" [GridDataSource]="data"
            [AllowSelection]="false" [AllowSorting]="false" [AllowPagination]="false" [AllowColumnReOrdering]="false"
            [ShowLineLevelAction]="false"></acv-grid>
        <ng-container *ngIf="data_type == 'text'">
          <p class="fw-500 fs-14 font-poppin mt-4 mb-4 text-content">{{data}}</p>
        </ng-container>

        <ng-container *ngIf="data_type == 'list'">
            <p class="fw-500 fs-14 font-poppin mt-4 mb-4" *ngFor="let item of data">{{item}}</p>
        </ng-container>

        <ng-container *ngIf="data_type == 'panel' && view_mode == 'grid' && trip_detail">
            <div class="grid m-0 mt-3">
                <div *ngFor="let milestone of data; let ind = index" class="col-12 md:col-12 lg:col-12 xl:col-12 p-0 mb-3">
                    <p-panel [toggleable]="true" [collapsed]="ind !== active_panelIndex ? true : false"
                        [className]="'panel-grey'" [collapseIcon]="'pi pi-chevron-up'"
                        [expandIcon]="'pi pi-chevron-down'" (onBeforeToggle)="onPanelColapseChange($event, ind)">
                        <ng-template pTemplate="header">
                            <div class="flex gap-2">
                                <p class="font-poppin fw-500 fs-14 text-color-black2">{{milestone?.name}}</p>
                            </div>
                        </ng-template>

                        <acv-grid [GridColumns]="gridColumns" [GridDataSource]="milestone.actions"
                            [AllowSelection]="false" [AllowSorting]="false" [AllowPagination]="false"
                            [AllowColumnReOrdering]="false" [ShowEditIcon]="title == 'Action' && (trip_detail?.status == 'Completed' || trip_detail?.status == 'In Progress') ? true : false"
                            [ShowLineLevelAction]="false" (GridAction)="onGridAction($event)"></acv-grid>
                    </p-panel>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="data_type == 'panel' && view_mode == 'map'">
            <div class="grid m-0 mt-3">
                <div class="col-12 md:col-12 lg:col-12 xl:col-12 p-0 mb-3">
                    <h4 class="section-title mb-3">Arrived at Pick-up location</h4>
                    <div class="flex flex-row justify-content-between"
                        style="background-color: #f9f9f9; border-radius: 10px; padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px;">
                        <div class="detail-item">
                            <p class="label">Action</p>
                            <p class="text">{{action_data?.name ? action_data?.name : '-'}}</p>
                        </div>
                        <div class="detail-item">
                            <p class="label">Driver Complete Date & Time</p>
                            <p class="text">{{action_data?.completed_at ? action_data?.completed_at : '-'}}</p>
                        </div>
                        <div class="detail-item">
                            <p class="label">Driver’s Location</p>
                            <p class="text">{{action_data?.destination ? action_data?.destination : '-'}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-12 xl:col-12 p-0 mt-3 mb-3">
                    <h4 class="section-title mb-2">Actual Complete Date</h4>
                    <form [formGroup]="actionForm" *ngIf="actionForm">
                        <div class="grid m-0">
                            <div class="col-12 md:col-6 lg:col-6 xl:col-6">
                                <acv-text-input [form]="actionForm" ControlName="completed_at"
                                    ControlLabelText="Actual Complete Date" [Required]="true"
                                    PlaceHolderText="Select Date" [ControlType]="'date'" [ShowWordCount]="false"
                                    [IsSubmited]="is_form_submit"></acv-text-input>
                            </div>
                            <div class="col-12 md:col-6 lg:col-6 xl:col-6">
                                <div class="input-elem">
                                    <p class="text-control-label mb-2">
                                        Actual Complete Time <sup>*</sup>
                                    </p>
                                    <div class="w-full flex gap-3 align-items-center timedd-container">
                                        <p>Hours</p>
                                        <p-dropdown [options]="hours_arr" [appendTo]="'body'"
                                            [formControlName]="'complete_hr'" [filter]="true" optionLabel="name"
                                            optionValue="name"></p-dropdown>
                                        <p-dropdown [options]="mins_arr" [appendTo]="'body'"
                                            [formControlName]="'complete_min'" [filter]="true" optionLabel="name"
                                            optionValue="id"></p-dropdown>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-12 md:col-12 lg:col-12 xl:col-12 p-0 mb-3" style="position: relative;">
                    <h4 class="section-title mb-3">Actual Location</h4>
                    <input id="autocomplete" type="text" [(ngModel)]="complete_address" [ngModelOptions]="{standalone: true}" placeholder="Search location" class="autocomplete-input" />
                    <google-map height="300px" width="100%" [center]="center" [zoom]="zoom">
                        <map-marker [position]="markerPosition" [options]="markerOptions" [draggable]="true"
                            (mapDragend)="onMarkerDragEnd($event)"></map-marker>
                    </google-map>
                </div>

            </div>
        </ng-container>

    </div>

    <ng-template pTemplate="footer">
        <p-button *ngIf="data_type == 'panel' && view_mode == 'map'" type="button" label="Cancel"
            class="p-button-text elevated-btn secondary mr-2" (click)="view_mode = 'grid'"></p-button>
        <p-button *ngIf="data_type == 'panel' && view_mode == 'map'" [disabled]="!actionForm.valid" type="button" [label]="'Update'"
            class="p-button-text elevated-btn primary mr-2" (click)="submit()"></p-button>
    </ng-template>

</p-dialog>